import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../../src/components/ConfirmCancelModalContext';
import { ProvidersList } from '../../src/components/ProvidersList';
import { FacebookTrackPageView } from '../../src/tracking/facebook';
import { GAIdentify } from '../../src/tracking/ga';
import { KoalaInstall } from '../../src/tracking/koala';
import { LinkedInInstall } from '../../src/tracking/linkedin';
import { GamePackPreviewMuteControlProvider } from './GamePack/GamePackDetailGamePreview';
import { PageAnalyticsSetup } from './PageAnalyticsSetup';

export function AnonProvider(props: { children?: React.ReactNode }) {
  const providers = [
    <ConfirmCancelModalProvider />,
    <GamePackPreviewMuteControlProvider />,
    <PageAnalyticsSetup />,
  ];

  return (
    <ProvidersList providers={providers}>
      {props.children}
      <ConfirmCancelModalRoot />
      <FacebookTrackPageView />
      <GAIdentify />
      <KoalaInstall />
      <LinkedInInstall />
    </ProvidersList>
  );
}
