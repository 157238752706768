// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect } from 'react';

import { getEnv } from '../config/getEnv';
import { marketing } from '../config/marketing';

function linkedInInstall() {
  const config = marketing(getEnv());
  if (!config.registrationMarketingTrackingEnabled) return;

  /* eslint-disable */
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push('3665338');

  (function (l) {
    if (!l) {
      window.lintrk = function (a, b) {
        window.lintrk.q.push([a, b]);
      };
      window.lintrk.q = [];
    }
    var s = document.getElementsByTagName('script')[0];
    var b = document.createElement('script');
    b.type = 'text/javascript';
    b.async = true;
    b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
    s.parentNode.insertBefore(b, s);
  })(window.lintrk);
  /* eslint-enable */
}

export function LinkedInInstall() {
  useEffect(() => {
    try {
      linkedInInstall();
    } catch (e) {
      console.error('Error installing LinkedIn', e);
    }
  }, []);
  return null;
}
