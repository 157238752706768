// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect } from 'react';

import { getEnv } from '../config/getEnv';
import { marketing } from '../config/marketing';

function koalaInstall() {
  const config = marketing(getEnv());
  if (!config.registrationMarketingTrackingEnabled) return;

  /* eslint-disable */
  !(function (f, b, e, v, n, t, s) {
    if (f.ko) return;
    f.ko = [];
    [
      'identify',
      'track',
      'removeListeners',
      'open',
      'on',
      'off',
      'qualify',
      'ready',
    ].forEach(function (t) {
      f.ko[t] = function () {
        var n = [].slice.call(arguments);
        return n.unshift(t), ko.push(n), ko;
      };
    });
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://cdn.getkoala.com/v1/pk_1b8147d1e01f511618b76462a92fc1e81d54/sdk.js'
  );
  /* eslint-enable */
}

export function KoalaInstall() {
  useEffect(() => {
    try {
      koalaInstall();
    } catch (e) {
      console.error('Error installing Koala', e);
    }
  }, []);
  return null;
}
