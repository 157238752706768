import { useNavigation } from '@remix-run/react';
import { useEffect } from 'react';

import { getEnv } from '../../config/getEnv';
import { marketing } from '../../config/marketing';
import { facebookInstall } from './install';

declare global {
  interface Window {
    fbq: (command: string, ...args: unknown[]) => void;
  }
}

function facebookTrackPageView() {
  const config = marketing(getEnv());
  if (!config.registrationMarketingTrackingEnabled) return;
  window.fbq('init', '1262369521228198');
  window.fbq('track', 'PageView');
}

export function FacebookTrackPageView() {
  const navigation = useNavigation();

  useEffect(() => {
    facebookInstall();
    // Only track page views when load is completed
    if (navigation.state !== 'idle') return;
    facebookTrackPageView();
  }, [navigation.state]);

  return null;
}
